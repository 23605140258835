// DetailsPage.js
import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import TableComponent from "../../components/TableComponent";
import Header from "../../components/Header";
import back from "../../assets/back.svg";
import { fetchMapData } from "../../api/api";
import { ACTIONS } from "../../context/chatReducer";
import { useChat } from "../../context/chatContext";
import toast from "react-hot-toast";

const DetailsPage = () => {
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("Mapping");
  const [isLoading, setIsLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [indexData, setIndexData] = useState({"data":[]});
  const navigate = useNavigate();
  const { state, dispatch } = useChat();

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const loadMappingData = async () => {
    const payload = {
        "id": id,
        }
    try {
      setIsLoading(true);
      setError(null);
      const historyData = await fetchMapData({payload});
      setIndexData(historyData);
      setData( Object.entries(historyData.data[0].mapping).map(([name, tabledata]) => ({
        name,
        tabledata
    })));
      setFilteredData( Object.entries(historyData.data[0].mapping).map(([name, tabledata]) => ({
        name,
        tabledata
    })));
    dispatch({
        type: ACTIONS.SET_TABLE_DATA,
        payload: {
          historyData,
          totalLength: 1
        }
      });
    } catch (err) {
    //   setError(err.message);
    setData([])
    setFilteredData([])
    toast.dismiss();
    toast.error(err.message)
      console.error('Failed to fetch history data:', err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
     loadMappingData();
  }, []);

  useEffect(() => {
    if (indexData.data.length > 0) {  // Safe check for indexData structure
      if (activeTab === "Mapping") {
        const mappingData = Object.entries(indexData.data[0].mapping).map(([name, tabledata]) => ({
          name,
          tabledata
        }));
        setData(mappingData);
        setFilteredData(mappingData);
      } 
      else if (activeTab === "Selected _columns") {
        const selectedColumns = indexData.data[0].selected_columns.map(name => ({
          name
        }));
        setData(selectedColumns);
        setFilteredData(selectedColumns);
      } 
      else if (activeTab === "Schema") {

        const transformSchemaToData = (schema) => {
          return schema.map((field) => {
            // Helper function to format boolean or missing values
            const formatBooleanValue = (value) => {
              if (value === true) return true;
              if (value === false) return false;
              return ''; // Empty string for missing or undefined values
            };
        
            const transformedField = {
              Field_name: field.name,
              Type: field.type,
              Key: formatBooleanValue(field.key),
              Retrievable: formatBooleanValue(field.retrievable),
              Searchable: formatBooleanValue(field.searchable),
              Facetable: formatBooleanValue(field.facetable),
              Sortable: formatBooleanValue(field.sortable),
              Stored: formatBooleanValue(field.stored),
              Filterable: formatBooleanValue(field.filterable),
              subItems: []
            };
        
            // If the field is a complex type, transform each nested field
            if (field.fields) {
              transformedField.subItems = field.fields.map((subField) => ({
                Field_name: subField.name,
                Type: subField.type,
                Key: "-", // Nested fields aren't keys by default
                Retrievable: formatBooleanValue(subField.retrievable),
                Searchable: formatBooleanValue(subField.searchable),
                Facetable: formatBooleanValue(subField.facetable),
                Sortable: formatBooleanValue(subField.sortable),
                Stored: formatBooleanValue(subField.stored),
                Filterable: formatBooleanValue(subField.filterable)
              }));
            }
        
            return transformedField;
          });
        };
        // const transformSchemaToData = (schema) => {
        //     return schema.map((field) => {
        //       // Base transformation for each field
        //       const transformedField = {
        //         Field_name: field.name,
        //         Type: field.type,
        //         Key: field.key || "",
        //         Retrievable: field.retrievable || "",
        //         Searchable: field.searchable || "",
        //         Facetable: field.facetable || "",
        //         Sortable: field.sortable || "",
        //         Stored: field.stored || "",
        //         Filterable: field.filterable || "", // Placeholder for fields without a corresponding property
        //         subItems: []
        //       };
          
        //       // If the field is a complex type, transform each nested field
        //       if (field.fields) {
        //         transformedField.subItems = field.fields.map((subField) => ({
        //           Field_name: subField.name,
        //           Type: subField.type,
        //           Key: "", // Nested fields aren't keys by default
        //           Retrievable: subField.retrievable,
        //           Searchable: subField.searchable || "",
        //           Facetable: subField.facetable || "",
        //           Sortable: subField.sortable || "",
        //           Stored: subField.stored || "",
        //           Filterable: subField.filterable || "" // Placeholder
        //         }));
        //       }
          
        //       return transformedField;
        //     });
        //   };
          
          // Usage
          const data1 = transformSchemaToData(indexData.data[0].schema);  
        setData(data1);
        setFilteredData(data1);
      }
    }
  }, [activeTab, indexData]);
  const handleSearch = useCallback(() => {
    setFilteredData(
      data.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    );
  }, [searchTerm, data]);
  useEffect(() => {
    handleSearch();
  }, [handleSearch]);
  return (
    <div className="h-full w-full">
      <Header />
      <div className="h-[88%]">
      <div className="h-[12%] w-full flex items-center justify-between p-2 px-6">
        <p className="text-[18px] font-semibold text-left text-[#12121B]">
          Index Info :
        </p>
        <div className="flex items-center gap-4">
        <div className="relative w-[300px]">
  <input
    type="text"
    className="border text-[12px] p-2 pl-4 pr-10 border-[#d9d9d9] outline-none w-full h-10 rounded-lg 
    text-ellipsis overflow-hidden whitespace-nowrap"
    placeholder="Search Index Name..."
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
  />
  <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="16" 
      height="16" 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="#888888" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    >
      <circle cx="11" cy="11" r="8"></circle>
      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
    </svg>
  </div>
</div>
          <div>
          </div>
        </div>
      </div>
      <div className="w-full h-[8%] flex items-start gap-2 px-6 mt-2">
        <div onClick={()=>{navigate("/AiSearch")}} className="mr-4 cursor-pointer"><img src={back} alt="back"/></div>
       <div className="flex items-center flex-wrap gap-2 justify-between"> <div className=" font-semibold"> ID :  <span className="text-[14px] font-normal">  {indexData.data.length > 0 ? indexData.data[0].id : id} </span> </div>
        <div className=" font-semibold">Table: <span className="text-[14px] font-normal">  {indexData.data.length > 0 ? indexData.data[0].table_name : "ok"}</span></div>
        <div className=" font-semibold">Index: <span className="text-[14px] font-normal">{indexData.data.length > 0 ?  indexData.data[0].index_name  : "ok" } </span> </div>
        <div className=" font-semibold">Target Column: <span className="text-[14px] font-normal"> {indexData.data.length > 0 ?  indexData.data[0].target_column  : "ok"} </span> </div>
        <div className=" font-semibold">Target Value: <span className="text-[14px] font-normal"> {indexData.data.length > 0 ?  indexData.data[0].target_value  : "ok" } </span></div>
      </div>
      </div>
      <div className="flex space-x-4 py-4 h-[16%] w-fit ml-4 2xl:h-[12%]">
        <button
          onClick={() => handleTabClick("Mapping")}
          className={`px-4 py-2 font-medium text-gray-700 ${
            activeTab === "Mapping"
              ? "border-b-4 border-[#006A6A] text-[#006A6A]"
              : "hover:border-gray-400"
          }`}
        >
          Mapping
        </button>
        <button
          onClick={() => handleTabClick("Schema")}
          className={`px-4 py-2 font-medium text-gray-700 ${
            activeTab === "Schema"
              ? "border-b-4 border-[#006A6A] text-[#006A6A]"
              : "hover:border-gray-400"
          }`}
        >
          Schema
        </button>
        <button
          onClick={() => handleTabClick("Selected _columns")}
          className={`px-4 py-2 font-medium text-gray-700 ${
            activeTab === "Selected _columns"
              ? "border-b-4 border-[#006A6A] text-[#006A6A]"
              : "hover:border-gray-400"
          }`}
        >
          Selected _columns
        </button>
      </div>
      <div className="p-4 h-[62%]">
        {activeTab === "Mapping" && (
          <div className="h-full">
            <TableComponent data={filteredData} />
          </div>
        )}
        {activeTab === "Schema" && (
          <div className="h-full schematable">
            <TableComponent data={filteredData} hasAccordion={true} />
          </div>
        )}
        {activeTab === "Selected _columns" && (
          <div className="h-full">
            <TableComponent data={filteredData} />{" "}
          </div>
        )}
      </div>
      </div>
    </div>
  );
};

export default DetailsPage;
