import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from '../pages/HomePage/Home';
import AiSearchPage from '../pages/AiSearchPage/AiSearchPage';
import NotFound from '../pages/NotFound/NotFound';
import Login from '../pages/Login/Login';
import DeleteIndex from '../pages/DeleteIndex/DeleteIndex';
import DetailsPage from '../pages/DetailsPage/DetailsPage';
import { useMsal } from '@azure/msal-react';
// import NotFoundPage from '../pages/NotFoundPage';
// 
const AppRouter = () => {


  return (
    <Routes>
    <Route path="/login" element={<Login />} />
    
    <Route path="/" element={
      <PrivateRoute>
        <Home />
    </PrivateRoute>
    } />
    
    <Route path="/deleteIndex" element={
      <PrivateRoute>
        <DeleteIndex />
       </PrivateRoute>
    } />
    
    <Route path="/AiSearch" element={
       <PrivateRoute>
        <AiSearchPage />
      </PrivateRoute>
    } />
    
    <Route path="/AiSearch/:id" element={
       <PrivateRoute>
        <DetailsPage />
        </PrivateRoute>
    } />
    
    <Route path="*" element={<NotFound />} />
  </Routes>
  );
};

export default AppRouter;


const hashValue = window.location.hash;
export const PrivateRoute = ({ children }) => {
  const { accounts } = useMsal();
  const isAuthenticated = accounts.length > 0 && sessionStorage.getItem("auth_token");
  if (
    isAuthenticated ||
    (window.location.pathname === "/" && hashValue !== "")
  ) {
    console.log(hashValue, "hashh")
    return children;
  } else {
    // If not authenticated, redirect to the login page
    console.log(hashValue, "hash")
    return <Navigate to="/login" />;
  }
  // return isAuthenticated ? children : <Navigate to="/login" replace />;
};
