export const msalConfig = {
    auth: {
      clientId: "4669a072-084c-48a2-8b95-0c047e6cf21c",
      authority: "https://login.microsoftonline.com/266493e0-7812-4f20-9384-7e36a3d03715",
      //  This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      allowRedirectInIframe: true,
      iframeHashTimeout: 6000,
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          console.log(message);
        },
        piiLoggingEnabled: false
      }
    }
  };
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
    scopes: ["User.Read"],
  };  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/users",
  };

