
export const fetchHistoryData = async ({ payload }) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/HistoryLogs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const rawData = await response.json();
    
    const transformedData = Array.isArray(rawData?.data) ? rawData?.data.map(item => ({
      ['document id']: item?.document_id || item?.id,
    ['sub type']: item?.subtype || "None",
      ['Data type']: item?.type,
      Index: item?.index,
      ['Logs From']: item?.logs_from,
      timestamp: item?.timestamp,
      status: item?.status,
      Error: item?.Error,
    })) : [];

    // Return both the transformed data and the total length
    return {
      data: transformedData,
      totalLength: rawData.Length[0] || 0
    };
    
  } catch (error) {
    console.error('Fetch error:', error);
    throw new Error(`Failed to fetch history data: ${error.message}`);
  }
};

export const fetchIndexData = async ({payload}) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/IndexInfo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    const rawData = await response.json();
    
    // Transform the data to only include required fields
    const transformedData = Array.isArray(rawData?.data) ? rawData.data.map(item => ({
      id: item.id,
      TableName: item.table_name,
      Index: item.index_name,
      ['Target Column']: item.target_column,
      ['Target Value']: item.target_value,
      ['Vector Column']:item.vector_column
    })) : [];
    // Return both the transformed data and the total length
    return {
      data: transformedData,
      totalLength: rawData.length[0] || 0
    };
  } catch (error) {
    throw new Error(`Failed to fetch Index data: ${error.message}`);
  }
};
export const fetchDeleteIndexData = async ({payload}) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/IndexInfo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const rawData = await response.json();
    
    // Transform the data to only include required fields
    const transformedData = Array.isArray(rawData?.data) ? rawData.data.map(item => ({
      id: item.id,
      Index: item.index_name,
      TableName: item.table_name,
    })) : [];
    // Return both the transformed data and the total length
    return {
      data: transformedData,
      totalLength: rawData.length[0] || 0
    };
  } catch (error) {
    throw new Error(`Failed to fetch Index data: ${error.message}`);
  }
};



export const fetchMapData = async ({payload}) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/IndexInfoDetails`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Failed to fetch Index data: ${error.message}`);
  }
};


export const DeleteIndexData = async ({payload}) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/DeleteIndex`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        // Add any authentication headers if needed
        // 'Authorization': `Bearer ${token}`,
      },
    });

    const rawData = await response.json();
    
    // Transform the data to only include required fields
    // Return both the transformed data and the total length
    return rawData;
  } catch (error) {
    throw new Error(`Failed to fetch history data: ${error.message}`);
  }
};