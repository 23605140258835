import React, { useState, useRef, useEffect } from "react";
import Sort from "../assets/Sort.svg";
import tick from "../assets/Tick.svg";
import Assending from "../assets/Assending.svg";
import Desending from "../assets/Desending.svg";
import { IoMdClose } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteModal from "./DeleteModal";
import { useChat } from "../context/chatContext";
import { ACTIONS } from "../context/chatReducer";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";

const TableComponent = ({
  data: initialData,
  hasAccordion = false,
  loadHistoryData,
}) => {
  const location = useLocation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [sortedData, setSortedData] = useState(initialData);
  const [localSortConfig, setLocalSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const overlayRef = useRef(null);
  const navigate = useNavigate();
  const { state, dispatch } = useChat();
  const { tableData, filteredData, sortConfig, selectedError, pagination, selectedObjectDetails } =
    state;
  const { currentPage, itemsPerPage, totalPages } = pagination;

  
  const headers =
    sortedData.length > 0
      ? Object.keys(sortedData[0]).filter((key) => key !== "subItems")
      : [];

  useEffect(() => {
    setSortedData(initialData);
  }, [initialData]);

  const toggleRow = (index) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(index)) {
      newExpandedRows.delete(index);
    } else {
      newExpandedRows.add(index);
    }
    setExpandedRows(newExpandedRows);
  };

  const renderCellValue = (value, header, item) => {
    if (value === null || value === undefined) return "";
    if (typeof value === 'boolean') {
      return value ? <img src={tick} alt="tick"/> : '-';
    }
  
    // Function to safely parse different object string formats
    const parseObjectString = (str) => {
      if (typeof str !== 'string') return null;
  
      // Try different parsing methods
      try {
        // Replace single quotes with double quotes and handle array-like strings
        const cleanedStr = str
          .trim()
          .replace(/'/g, '"')
          .replace(/\[/g, '[')
          .replace(/\]/g, ']');
        const parsedObj = JSON.parse(cleanedStr);
        return parsedObj;
      } catch (jsonError) {
        try {
          // Fallback to eval (use with caution)
          const evalParsed = eval('(' + str + ')');
          return evalParsed;
        } catch (evalError) {
          console.warn('Failed to parse object string', str);
          return null;  
        }
      }
    };
  
    // Handle error column
    if (header === "Error" && typeof value === "string" && value.length > 30) {
      return (
        <>
          {value.slice(0, 30)}  
          <button
            className="ml-2 text-blue-500 hover:text-blue-700"
            onClick={(e) => {
              e.stopPropagation();
              dispatch({
                type: ACTIONS.SET_SELECTED_ERROR,
                payload: value,
              });
            }}
          >
            ...More
          </button>
        </>
      );
    }
  
    // Handle array of objects or object-like strings for TargetValue and Vector
    const handleObjectDisplay = (objValue) => {
      // If it's a string, parse it
      const parsedValue = typeof objValue === 'string' 
        ? parseObjectString(objValue) 
        : objValue;
  
      if (!parsedValue) return value.toString();
  
      // Ensure we're working with an array
      const objectArray = Array.isArray(parsedValue) ? parsedValue : [parsedValue];
  
      // Convert array of objects to a readable string with specific format for Vector and TargetValue
      const displayParts = objectArray.map((obj, index) => {
        if (typeof obj !== 'object' || obj === null) return obj.toString();
  
        // Prioritize specific keys for Vector and TargetValue
        const nameKey = obj.name || obj.Name || obj.label || obj.Label;
        const valueKey = obj.value || obj.Value || obj.data || obj.Data;
        
        const displayText = JSON.stringify(obj);
        
        return displayText;
      });
  
      const combinedValue = displayParts.join(', ');
  
      if (combinedValue.length > 30) {
        return (
          <>
            {combinedValue.slice(0, 30)}
            <button
              className="ml-2 text-blue-500 hover:text-blue-700"
              onClick={(e) => {
                e.stopPropagation();
                dispatch({
                  type: ACTIONS.SET_SELECTED_OBJECT_DETAILS,
                  payload: objectArray,
                });
              }}
            >
              ...More
            </button>
          </>
        );
      }
      return combinedValue;
    };
  
    // Check for array-like or object-like values for TargetValue and Vector
    if (
      header === "TargetValue" || 
      header === "Vector" ||
      (typeof value === "string" && 
        (value.includes("[{") || value.includes("{"))) ||
      (Array.isArray(value) || (typeof value === "object" && value !== null))
    ) {
      return handleObjectDisplay(value);
    }
  
    // Handle status 
    if (header === "status") {
      return (
        <span
          className={`text-[12px] ${
            value === "Completed" || value === "200" || value === "completed"
              ? "text-[#009106]"
              : "text-[#D14E26]"
          }`}
        >
        <div 
          className={`inline-block w-2 h-2 mr-2 rounded-full ${value === "Completed" || value === "200" || value === "completed"
            ? "bg-[#009106]"
            : "bg-[#D14E26]"
        }`}
        ></div>
        {value}
        </span>
      );
    }
  
    // Default case
    return value.toString();
  };


  // const renderCellValue = (value, header, item) => {
  //   if (value === null || value === undefined) return "";
  
  //   // Function to safely parse different object string formats
  //   const parseObjectString = (str) => {
  //     if (typeof str !== 'string') return null;
  
  //     // Try different parsing methods
  //     try {
  //       // Remove any trailing characters that might break JSON parsing
  //       const cleanedStr = str.trim().replace(/}.*$/, '}');
  //       const parsedObj = JSON.parse(cleanedStr);
  //       return parsedObj;
  //     } catch (jsonError) {
  //       try {
  //         // Fallback to eval (use with caution)
  //         const evalParsed = eval('(' + str + ')');
  //         return evalParsed;
  //       } catch (evalError) {
  //         console.warn('Failed to parse object string', str);
  //         return null;
  //       }
  //     }
  //   };
  
  //   // Handle error column
  //   if (header === "ERROR" && typeof value === "string" && value.length > 30) {
  //     return (
  //       <>
  //         {value.slice(0, 30)}
  //         <button
  //           className="ml-2 text-blue-500 hover:text-blue-700"
  //           onClick={(e) => {
  //             e.stopPropagation();
  //             dispatch({
  //               type: ACTIONS.SET_SELECTED_ERROR,
  //               payload: value,
  //             });
  //           }}
  //         >
  //           ...More
  //         </button>
  //       </>
  //     );
  //   }
  
  //   // Handle object-like strings or JSON objects
  //   const handleObjectDisplay = (objValue) => {
  //     // If it's already an object, use it directly
  //     const parsedValue = typeof objValue === 'string' 
  //       ? parseObjectString(objValue) 
  //       : objValue;
  
  //     if (!parsedValue || typeof parsedValue !== 'object') {
  //       return value.toString();
  //     }
  
  //     // Convert object to a readable string
  //     const displayParts = Object.entries(parsedValue).map(([key, val]) => 
  //       `${key}: ${val}`
  //     );
  //     const combinedValue = displayParts.join(', ');
  
  //     if (combinedValue.length > 30) {
  //       return (
  //         <>
  //           {combinedValue.slice(0, 30)}
  //           <button
  //             className="ml-2 text-blue-500 hover:text-blue-700"
  //             onClick={(e) => {
  //               e.stopPropagation();
  //               dispatch({
  //                 type: ACTIONS.SET_SELECTED_OBJECT_DETAILS,
  //                 payload: parsedValue,
  //               });
  //             }}
  //           >
  //             ...More
  //           </button>
  //         </>
  //       );
  //     }
  //     return combinedValue;
  //   };
  
  //   // Check for object-like values
  //   if (typeof value === "string" && 
  //       (value.includes("{") && value.includes("}")) ||
  //       (typeof value === "object" && value !== null)) {
  //     return handleObjectDisplay(value);
  //   }
  
  //   // Handle objects with 'label' and 'value' keys
  //   if (typeof value === "string" && 
  //       (value.includes("'label':") || 
  //        value.includes('"label":') || 
  //        value.startsWith("{") || 
  //        value.includes("label"))) {
      
  //     const parsedValue = parseObjectString(value);
      
  //     if (parsedValue) {
  //       // Try to find label and value, handling different possible key names
  //       const label = parsedValue.label || parsedValue.Label || parsedValue.name || parsedValue.Name;
  //       const displayValue = parsedValue.value || parsedValue.Value || parsedValue.data || parsedValue.Data;
        
  //       const combinedValue = label && displayValue 
  //         ? `${label}: ${displayValue}` 
  //         : JSON.stringify(parsedValue);
        
  //       if (combinedValue.length > 30) {
  //         return (
  //           <>
  //             {combinedValue.slice(0, 30)}
  //             <button
  //               className="ml-2 text-blue-500 hover:text-blue-700"
  //               onClick={(e) => {
  //                 e.stopPropagation();
  //                 dispatch({
  //                   type: ACTIONS.SET_SELECTED_OBJECT_DETAILS,
  //                   payload: parsedValue,
  //                 });
  //               }}
  //             >
  //               ...More
  //             </button>
  //           </>
  //         );
  //       }
  //       return combinedValue;
  //     }
  //   }
  
  //   // Handle other object types
  //   if (typeof value === "object" && value !== null) {
  //     return JSON.stringify(value);
  //   }
  
  //   // Handle status and target value
  //   if (header === "status" || header === "TargetValue") {
  //     return (
  //       <span
  //         className={`text-[12px] ${
  //           value === "completed" || value === "200"
  //             ? "text-[#009106]"
  //             : "text-[#E19500]"
  //         }`}
  //       >
  //         {value}
  //       </span>
  //     );
  //   }
  
  //   // Default case
  //   return value.toString();
  // };

  // const renderCellValue = (value, header, item) => {
  //   if (value === null || value === undefined) return "";

  //   if (header === "ERROR" && typeof value === "string" && value.length > 30) {
  //     return (
  //       <>
  //         {value.slice(0, 30)}
  //         <button
  //           className="ml-2 text-blue-500 hover:text-blue-700"
  //           onClick={(e) => {
  //             e.stopPropagation();
  //             dispatch({
  //               type: ACTIONS.SET_SELECTED_ERROR,
  //               payload: value,
  //             });
  //           }}
  //         >
  //           ...More
  //         </button>
  //       </>
  //     );
  //   }

  //   if (header === "status" || header === "TargetValue") {
  //     return (
  //       <span
  //         className={`text-[12px] ${
  //           value === "completed" || value === "200"
  //             ? "text-[#009106]"
  //             : "text-[#E19500]"
  //         }`}
  //       >
  //         {value}
  //       </span>
  //     );
  //   }

  //   if (typeof value === "object" && value !== null) {
  //     return JSON.stringify(value);
  //   }

  //   return value.toString();
  // };

  const compareValues = (aValue, bValue, direction) => {
    // Handle null/undefined values
    if (aValue === null || aValue === undefined) {
      return direction === "ascending" ? -1 : 1; // Nulls first in ascending, last in descending
    }
    if (bValue === null || bValue === undefined) {
      return direction === "ascending" ? 1 : -1;
    }

    // Handle different types of values
    if (typeof aValue === "string" && typeof bValue === "string") {
      return direction === "ascending"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    // For numbers and other types
    if (aValue < bValue) return direction === "ascending" ? -1 : 1;
    if (aValue > bValue) return direction === "ascending" ? 1 : -1;
    return 0;
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      localSortConfig.key === key &&
      localSortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }

    const newSortedData = [...sortedData].sort((a, b) => {
      const aValue = a[key];
      const bValue = b[key];

      if (key === "Timestamp") {
        const dateA = new Date(aValue);
        const dateB = new Date(bValue);
        return direction === "ascending" ? dateA - dateB : dateB - dateA;
      }

      return compareValues(aValue, bValue, direction);
    });

    setSortedData(newSortedData);
    setLocalSortConfig({ key, direction });
  };

  const handlePageChange = (newPage) => {
    dispatch({ type: ACTIONS.SET_CURRENT_PAGE, payload: newPage });
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        dispatch({ type: ACTIONS.SET_SELECTED_ERROR, payload: null });
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  return (
    <div className="relative h-full">
      <div
        className={`overflow-auto ${totalPages > 1 ? "h-[86%]" : "h-[93%]"} `}
      >
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 mb-[4px]">
          <thead className="text-xs text-gray-700 capitalize bg-[#E8F6F6]">
            <tr>
              {headers.map((header, index) => (
                <th
                key={header}
                scope="col"
                className={`px-4 py-3 text-[12px] text-left font-medium text-[#4F4F4F] whitespace-nowrap cursor-pointer ${
                  index === 0 && hasAccordion ? "pl-12" : ""
                }`}
                onClick={() => requestSort(header)}
                >
                  <div
                    className={`flex gap-[2px] items-center ${
                      index === 0 && !hasAccordion ? "pl-10" : ""
                    } `}
                  >
                    {header}
                    <img
                      src={
                        localSortConfig.key === header
                          ? localSortConfig.direction === "ascending"
                            ? Assending
                            : Desending
                          : Sort
                      }
                      alt="Sort"
                    />
                  </div>
                </th>
              ))}
              {location.pathname === "/deleteIndex" && (
                <th
                  scope="col"
                  className="px-6 py-3 text-[12px] font-medium text-left text-[#4F4F4F]"
                >
                  Action
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {sortedData.length > 0 ? (
              sortedData.map((item, index) => (
                <React.Fragment key={index}>
                  <tr
                    className={`bg-white border-b hover:bg-[#E8F6F6] ${
                      hasAccordion && item.subItems?.length
                        ? "cursor-pointer"
                        : ""
                    }`}
                  >
                    {headers.map((header, headerIndex) => (
                      <td
                        key={header}
                        className={`px-5 py-4 text-[12px] text-[#454545] text-left whitespace-nowrap ${
                          headerIndex === 0 ? "relative" : ""
                        } ${
                          location.pathname === "/AiSearch" && headerIndex == 0
                            ? "cursor-pointer"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            location.pathname === "/AiSearch" &&
                            headerIndex == 0
                          ) {
                            navigate(`/AiSearch/${item.id}`);
                            dispatch({
                              type: ACTIONS.SET_CURRENT_PAGE,
                              payload: 1,
                            });
                          }
                        }}
                      >
                        {headerIndex === 0 && hasAccordion && (
                          <span
                            className={`absolute left-4 top-1/2 transform -translate-y-1/2 ${
                              !item.subItems?.length ? "invisible" : ""
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (item.subItems?.length) {
                                toggleRow(index);
                              }
                            }}
                          >
                            {expandedRows.has(index) ? (
                              <BiChevronDown className="w-5 h-5" />
                            ) : (
                              <BiChevronRight className="w-5 h-5" />
                            )}
                          </span>
                        )}
                        <span className={headerIndex === 0 ? "ml-8" : ""}>
                          {renderCellValue(item[header], header, item)}
                        </span>
                      </td>
                    ))}
                    {location.pathname === "/deleteIndex" && (
                      <td className="px-6 py-4">
                        <button
                          className="px-6 py-1 gap-4 bg-[#169F9F33]"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowDeleteModal(item.Index);
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    )}
                  </tr>
                  {hasAccordion &&
                    expandedRows.has(index) &&
                    item.subItems?.map((subItem, subIndex) => (
                      <tr
                        key={`${index}-${subIndex}`}
                        className="bg-gray-50 border-b ml-2"
                      >
                        {headers.map((header, headerIndex) => (
                          <>
                          <td
                            key={header}
                            className={`px-6 py-4 text-[12px] text-[#454545] whitespace-nowrap ${
                              headerIndex === 0 ? "pl-12" : ""
                            }`}
                          >
                            {renderCellValue(subItem[header], header, subItem)}
                          </td>
                          </>
                        ))}
                        {location.pathname === "/deleteIndex" && (
                          <td className="px-6 py-2">
                            <button
                              className="px-6 py-1 gap-4 bg-[#169F9F33]"
                              onClick={() => setShowDeleteModal(item.Index)}
                            >
                              Delete
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td
                  colSpan={
                    headers.length +
                    (location.pathname === "/deleteIndex" ? 1 : 0)
                  }
                  className="text-center py-4 text-gray-500"
                >
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {totalPages > 1 && (
        <div className="flex items-center justify-end space-x-2 mt-4 mb-4 pr-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-3 py-1 rounded ${
              currentPage === 1
                ? "text-gray-400 cursor-not-allowed"
                : "text-blue-600 hover:bg-blue-100"
            }`}
          >
            ‹
          </button>
          {getPageNumbers().map((number) => (
            <button
              key={number}
              onClick={() => handlePageChange(number)}
              className={`px-3 py-1 rounded ${
                currentPage === number
                  ? "bg-blue-500 text-white"
                  : "text-blue-600 hover:bg-blue-100"
              }`}
            >
              {number}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-3 py-1 rounded ${
              currentPage === totalPages
                ? "text-gray-400 cursor-not-allowed"
                : "text-blue-600 hover:bg-blue-100"
            }`}
          >
            ›
          </button>
        </div>
      )}
      {selectedObjectDetails && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div
      ref={overlayRef}
      className="bg-white p-6 rounded-lg max-w-xl w-full max-h-[70vh] overflow-hidden flex flex-col"
    >
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-semibold">Full Details</h3>
        <button
          className="text-gray-600 hover:text-gray-900"
          onClick={() =>
            dispatch({
              type: ACTIONS.SET_SELECTED_OBJECT_DETAILS,
              payload: null,
            })
          }
        >
          <IoMdClose className="w-6 h-6" />
        </button>
      </div>
      <div className="overflow-y-auto flex-grow">
        {Array.isArray(selectedObjectDetails) ? (
          selectedObjectDetails.map((obj, index) => (
            <div 
              key={index} 
              className="bg-gray-50 p-3 rounded-md border border-gray-200 mb-2"
            >
              <div className="space-y-1">
                {Object.entries(obj).map(([key, value]) => (
                  <div 
                    key={key} 
                    className="flex"
                  >
                    <span className="font-medium text-gray-700 mr-2">{key}:</span>
                    <span className="text-gray-900 break-words">{value}</span>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <div 
            className="bg-gray-50 p-3 rounded-md border border-gray-200"
          >
            {Object.entries(selectedObjectDetails).map(([key, value]) => (
              <div 
                key={key} 
                className="flex"
              >
                <span className="font-medium text-gray-700 mr-2">{key}:</span>
                <span className="text-gray-900 break-words">{value}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  </div>
)}
{/* {selectedObjectDetails && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div
      ref={overlayRef}
      className="bg-white p-6 rounded-lg max-w-xl w-full max-h-[70vh] overflow-hidden flex flex-col"
    >
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-semibold">Full Object Details</h3>
        <button
          className="text-gray-600 hover:text-gray-900"
          onClick={() =>
            dispatch({
              type: ACTIONS.SET_SELECTED_OBJECT_DETAILS,
              payload: null,
            })
          }
        >
          <IoMdClose className="w-6 h-6" />
        </button>
      </div>
      <div className="overflow-y-auto flex-grow">
        <div className="space-y-2">
          {selectedObjectDetails && Object.entries(selectedObjectDetails).map(([key, value]) => (
            <div 
              key={key} 
              className="bg-gray-50 p-3 rounded-md border border-gray-200"
            >
              <span className="font-medium text-gray-700 mr-2">{key}:</span>
              <span className="text-gray-900 break-words">{value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)} */}
      {selectedError && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div
            ref={overlayRef}
            className="bg-white p-6 rounded-lg max-w-xl max-h-[28vh] overflow-auto"
          >
            <div className="flex items-center justify-between mb-4">
              <h3 className="items-center">Error Information: </h3>
              <p
                className="cursor-pointer"
                onClick={() =>
                  dispatch({
                    type: ACTIONS.SET_SELECTED_ERROR,
                    payload: null,
                  })
                }
              >
                <IoMdClose />
              </p>
            </div>
            <p className="text-gray-700">{selectedError}</p>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <DeleteModal
          SetShowdeleteModal={setShowDeleteModal}
          ShowDeleteModal={showDeleteModal}
          loadHistoryData={loadHistoryData}
        />
      )}
    </div>
  );
};

export default TableComponent;