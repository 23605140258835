import React, { useCallback, useEffect, useState } from "react";
import TableComponent from "../../components/TableComponent";
import filters from "../../assets/Filter.svg";
import Header from "../../components/Header";
import FilterModal from "../../components/filterModal";
import { fetchDeleteIndexData, fetchIndexData } from "../../api/api";
import { useChat } from "../../context/chatContext";
import { ACTIONS } from "../../context/chatReducer";
import toast from "react-hot-toast";
 

const DeleteIndex = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Index");
  const [selectedFilters, setSelectedFilters] = useState({
    Index: [],
    TableName: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const { state, dispatch } = useChat();
  const { pagination } = state;
  const { currentPage, itemsPerPage, totalPages } = pagination;
  const [ShowDeleteModal, SetShowdeleteModal] = useState(null);

  const loadHistoryData = async (start=null) => {
    const payload = {
      "start_index": start ? start-1 :(currentPage-1)* 10,
      }
    try {
      setIsLoading(true);
      setError(null);
      const { data, totalLength } = await fetchDeleteIndexData({ payload });
    dispatch({
      type: ACTIONS.SET_TABLE_DATA,
      payload: {
        data,
        totalLength
      }
    });
    // If you're using local state
    setData(data);
    setFilteredData(data);
    } catch (err) {
      // setError(err.message);
      setData([])
      setFilteredData([])
      toast.dismiss();
      toast.error(err.message)
      console.error('Failed to fetch history data:', err);
    } finally {
      setIsLoading(false);
    }
  };

useEffect(() => {
  loadHistoryData();
}, [currentPage]);

const handleSearch = useCallback(() => {
  setFilteredData(
    data.filter((item) => {
      const Index = item.Index || ''; // Handle cases where index_name might be undefined
      return Index.toLowerCase().includes(searchTerm.toLowerCase());
    })
  );
}, [searchTerm]);

useEffect(() => {
  handleSearch();
}, [handleSearch]);
  
  if (isLoading) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <div className="text-red-600 text-center">
          <p className="text-xl font-semibold">Error loading data</p>
          <p className="text-sm mt-2">{error}</p>
          <button 
            onClick={loadHistoryData}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="h-full w-full">
      <Header />
      <div className="h-[88%]">
      <div className="h-[12%] w-full flex items-center justify-between p-2 px-6">
        <p className="text-[18px] font-semibold text-left text-[#12121B]">
        Index Table: 
        </p>
        <div className="flex items-center gap-4">
        <div className="relative w-[300px]">
  <input
    type="text"
    className="border text-[12px] p-2 pl-4 pr-10 border-[#d9d9d9] outline-none w-full h-10 rounded-lg 
    text-ellipsis overflow-hidden whitespace-nowrap"
    placeholder="Search Index Name..."
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
  />
  <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="16" 
      height="16" 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="#888888" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    >
      <circle cx="11" cy="11" r="8"></circle>
      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
    </svg>
  </div>
</div>
          <div>
          </div>
        </div>
      </div>
      <div className="h-[88%]">
      <TableComponent data={filteredData} ShowDeleteModal={ShowDeleteModal} SetShowdeleteModal={SetShowdeleteModal} loadHistoryData={loadHistoryData} />  
     </div>
        </div>
    </div>
  );
};

export default DeleteIndex;
