
import React, { useState } from 'react';
import profile from '../assets/profile.svg';
import logo from '../assets/Logo.svg';
import  down from '../assets/chevronDown.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useChat } from '../context/chatContext';
import { ACTIONS } from "../context/chatReducer";

const Header = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useChat();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname == "/" ? 'history': (location.pathname == "/deleteIndex" ? 'Delete' : 'indexes'));
  const [showLogout, setShowLogout] = useState(false);
  
  const logout = () => {
    sessionStorage.removeItem("auth_token");
    navigate("/login");
  };

  return (
    <div className='h-[12%] p-4 border-b border-[#EDEDED] w-full flex justify-between items-center'>
      <div className='flex gap-[3rem]'>
        <img src={logo} alt='logo' />
        <div>
          <ul className="flex flex-wrap text-sm gap-3 font-medium text-center text-gray-500">
          <li className="me-2">
              <button
                onClick={() => {
                  setActiveTab('indexes')
                  navigate("/AiSearch")
                  dispatch({ type: ACTIONS.SET_CURRENT_PAGE, payload: 1 });
                }}
                className={`inline-block p-2 px-4 rounded-t-lg ${activeTab === 'indexes' ? 'text-[#169f9f] bg-gray-100 ' : 'hover:text-gray-600 hover:bg-gray-50 '}`}
              >
                Index Info
              </button>
            </li>
            <li className="me-2">
              <button
                onClick={() => {
                  setActiveTab('history')
                  dispatch({ type: ACTIONS.SET_CURRENT_PAGE, payload: 1 });
                  navigate("/")
                }}
                className={`inline-block p-2 px-4 rounded-t-lg ${activeTab === 'history' ? 'text-[#169f9f] bg-gray-100' : 'hover:text-gray-600 hover:bg-gray-50 '}`}
              >
                History LOGS
              </button>
            </li>
            <li className="me-2">
              <button
                onClick={() => {
                  setActiveTab('Delete')
                  navigate("/deleteIndex")
                  dispatch({ type: ACTIONS.SET_CURRENT_PAGE, payload: 1 });
                }}
                className={`inline-block p-2 px-4 rounded-t-lg ${activeTab === 'Delete' ? 'text-[#169f9f] bg-gray-100 ' : 'hover:text-gray-600 hover:bg-gray-50 '}`}
              >
                Delete
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="relative">
        <button 
          onClick={() => setShowLogout(!showLogout)}
          className="focus:outline-none flex gap-2 items-center"
        >
          <img src={profile} alt='profile' />
          <img src={down} alt='profile' />

        </button>
        
        {showLogout && (
          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
            <button
              onClick={logout}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;